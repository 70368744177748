<template>
  <div class="flex flex-row">
    <div class="csv-upload gap-19 flex auto-box shadow-sm rounded-sm">
      <div class="auto-box-inner w-full">
        <h4>Generate Your Posts</h4>

        <b-form-group label-for="hashtags" class="mt-2">
          <template slot="label">Tell Us About the Type of Post/Campaign you want to Create</template>
          <b-textarea :value="prompt" @input="handlePromptChange" class="mb-2" rows="10" />
        </b-form-group>
        <b-alert show variant="warning">
          <div class="flex flex-row gap-2 items-center">
            <b-icon icon="exclamation-triangle" scale="1.5"></b-icon>
            <span class=" font-normal text-sm">
              Our AI will use this brief info to create post ideas, design images, captions and schedule for you
              instantly. You will be able to customise further in the following step
            </span>
          </div>
        </b-alert>

        <div v-if="results.length" class="py-4">
          <table>
            <tr>
              <th class="p-2">Title</th>
              <th class="p-2">Content</th>
              <th class="p-2">Cover Image</th>
            </tr>
            <tr v-for="(data, idx) in results" :key="idx">
              <td class="p-2">{{ data.title }}</td>
              <td class="p-2">{{ data.content | truncate(200) }}</td>
              <td>
                <div class="p-2">
                  <img :src="data.cover_image" style="width: 100px; height: 100px" />
                </div>
              </td>
            </tr>
          </table>

          <div class="mt-3">
            <b-btn @click="goNext"> Continue </b-btn>
          </div>
        </div>
      </div>

      <div class="w-[200px] relative ">
        <div class="flex items-center">
          <h5>Sample Prompts</h5>
          <div v-if="showAlert" class="_alert">Prompt copied!</div>
          <div v-if="showAlert" class="tooltipText"></div>
        </div>

        <div class="border font-normal text-sm p-3 rounded-md rounded-b-none">
          <div>
            {{ samplePrompt }}
          </div>
        </div>
        <button
          @click="handlePromptCopy(samplePrompt)"
          style="border: none"
          class="w-full rounded-b-md text-sm py-1 font-normal text-[---base-green] bg-[---dark-green]"
        >
          Copy Sample Prompt
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isValidUrl } from '~/utils/helpers';
// import SvgIcon from './SvgIcon.vue';

export default {
  // components: { SvgIcon },
  name: 'automation-ai',
  data: () => ({
    postCount: 0,
    completedCount: 0,
    results: [],
    worker: null,
    samplePrompt:
      'We run a [business type] focused on [specific niche/product/service]. Our target audience consists of [brief description of audience demographics and interests]. We want to create [specific type of content, e.g., educational, promotional, entertaining] posts/campaigns that will resonate with them.',
    showAlert: false,
  }),
  computed: {
    ...mapState('createAutomation', {
      source: state => state.source,
    }),
    ...mapState('createAutomation', {
      prompt: state => state.aiPostPrompt,
      count: state => state.count_per_request,
    }),
    urlState() {
      if (this.source === '') return null;
      return isValidUrl(this.source);
    },
  },

  filters: {
    truncate(string, value) {
      return (string || '').substring(0, value) + '…';
    },
  },

  methods: {
    handlePromptChange(prompt) {
      this.$store.dispatch('createAutomation/updateAiPostPrompt', prompt);
    },

    handlePromptCopy(text) {
      this.$store.dispatch('createAutomation/updateAiPostPrompt', text);
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
    },

    handleCountChange(count) {
      this.$store.dispatch('createAutomation/updateAiPostCount', +count);
    },

    goNext() {
      this.handleCsvFileChange(this.convertCsvToFile(this.convertArrayOfObjectsToCSV(this.results)));
      this.$emit('next');
    },

    convertCsvToFile(csvData, fileName = 'untitled.csv') {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const file = new File([blob], fileName, { type: 'text/csv' });
      return file;
    },

    async generate() {
      for (let i = 0; i < this.postCount; i++) {
        try {
          await this.$store.dispatch('createAutomation/generatePost', {
            message: this.prompt,
            postType: this.$route.params.type,
          });
          this.results = this.results.filter(e => e);
          this.results.push(this.response);
        } catch (error) {
          alert(error.message);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

table {
  border-radius: 5px;
  & td,
  & th {
    border: 1px solid #77777755;
    & img {
      border-radius: 5px;
    }
  }
}
.csv-upload {
  .desc {
    color: $gray-500;
  }
}
.i-width {
  padding: 12px;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 5px;
}
._alert {
  position: absolute;
  right: 5px;
  top: -10px;
  padding: 8px;
  background-color: #1b4332;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
}
.tooltipText::before {
  content: '';
  position: absolute;
  right: 8%;
  top: 19px;
  transform: translateX(-90%);
  border-width: 15px;
  border-style: solid;
  border-color: #1b4332 transparent transparent transparent;
  display: block;
}
</style>
