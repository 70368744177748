<template>
  <div>
    <ol class="user__data-stat">
      <li class="user__data-stat-list">
        <router-link :to="{ name: 'posts' }" class="stat-box d-block">
          <span class="badge badge_2">{{ numFormat(totalPosts) }}</span>
          <span class="title">Total Posts</span>
        </router-link>
      </li>
      <li class="user__data-stat-list">
        <router-link :to="{ name: 'automation.home' }" class="stat-box d-block">
          <span class="badge badge_1">{{ numFormat(totalAutomations) }}</span>
          <span class="title">Automations</span>
        </router-link>
      </li>
      <!-- <li class="user__data-stat-list">
        <router-link :to="{ name: 'teams' }" class="stat-box d-block">
          <span class="badge badge_3">{{ numFormat(0) }}</span>
          <span class="title">Team Members</span>
        </router-link>
      </li> -->
    </ol>
  </div>
</template>

<script>
import { numFormat } from '~/utils/helpers';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      totalPosts: 0,
      totalAutomations: 0,
    }
  },
  mounted() {
    this.fetchStats().then((stats) => {
      this.totalPosts = stats.totalPosts;
      this.totalAutomations = stats.totalAutomations;
    });
  },
  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },
  methods: {
    numFormat,
    async fetchStats() {
      try {
        const response = await fetch(process.env.VUE_APP_API_ROOT + '/api/total-number/posts?workspace_id=' + this.workspace?.id);
        const data = await response.json();
        return {
          totalPosts: data.total_posts || 0,
          totalAutomations: data.total_automations || 0
        };
      } catch (error) {
        console.error('Error fetching stats:', error);
        return {
          totalPosts: 0,
          totalAutomations: 0
        };
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.user__data-stat {
  max-height: $navbar-height;
  list-style: none;
  margin: 0;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  border-radius: 0 0 30px 30px;
  background-color: transparent;
  width: 100%;
  &-list {
    background-color: none;
    color: #000000;
    min-width: 100px;
    line-height: 10px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    padding: 2px 10px;
    &:first-child {
      border: none;
    }
    .stat-box {
      color: inherit;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
    .badge {
      min-width: 25px;
      min-height: 25px;
      line-height: 25px;
      border-radius: 100%;
      font-weight: bold;
      vertical-align: middle;
      font-size: 14px;
      text-align: center;
      box-sizing: content-box;
      white-space: nowrap;
      padding: 5px;
      margin-right: 5px;
      &_1 {
        color: #d8f3dc;
        background: #52b788;
      }
      &_2 {
        color: #ffddaa;
        background: #d49f4e;
      }
      &_3 {
        color: #ffd2d2;
        background: #d87d7d;
      }
    }
    .title {
      font-weight: bold;
      font-size: 14px;
      text-align: left;
    }
  }
}
</style>
