<template>
  <b-modal
    modal-class="dashboard-tutorial-modal"
    :visible="visible"
    id="DashboardTutorialModal"
    centered
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    @hide="handleOnHide"
  >
    <div slot="modal-header" slot-scope="{ close }">
      Learn to Use SocialMediaAgency360

      <button @click="close()" class="panel-header-button"><svg-icon name="close" /></button>
    </div>
    <b-container>
      <b-row>
        <b-col md="4" class="navigation">
          <ul class="tutorial-nav">
            <li v-for="(step, i) in steps" :key="step.id" @click="handleNext(i)">
              <div class="tutorial-nav-label">{{ step.title }}</div>
              <div class="tutorial-nav-active-indicator" :class="{ active: step.id === activeStep.id }"></div>
            </li>
          </ul>
        </b-col>
        <b-col md="8" class="main-content">
          <div class="px-4 mt-5">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="mb-0 font-weight-bold">{{ activeStep.title }}</h5>

              <div class="d-flex align-items-center">
                <b-badge class="rounded-pill font-weight-bold mr-3 px-3 py-1 border"
                  >{{ activeStep.id }} of {{ steps.length }}</b-badge
                >
                <b-button @click="handlePrev" class="btn-arrow mr-2 rounded-circle" size="sm" variant="light">
                  <svg-icon class="mt-0" name="chevron-left" />
                </b-button>
                <b-button @click="handleNext(false)" class="btn-arrow rounded-circle" size="sm" variant="light">
                  <svg-icon class="mt-0" name="chevron-right" />
                </b-button>
              </div>
            </div>
            <p class="main-text mt-4">{{ activeStep.description }}</p>

            <div class="video-player-wrap">
              <iframe
                v-if="activeStep.video"
                width="100%"
                height="245"
                :src="activeStep.video"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <img v-else class="w-100" :src="require(`@/assets/images/banners/${activeStep.image}`)" alt="" />
            </div>

            <p class="small mt-2">
              Learn how to navigate and use SocialMediaAgency360, and get the confidence to accomplish your goals
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div slot="modal-footer" slot-scope="{ close }">
      <div class="text-right">
        <div class="d-flex align-items-center">
          <b-form-checkbox v-model="neverShowAgain" class="tutorial-toggle mr-4"> Never show again </b-form-checkbox>
          <b-button v-if="activeStep.id !== 6" @click="handleNext(false)" class="px-4" size="sm" variant="secondary"
            >Next</b-button
          >
          <b-button v-else @click="close()" class="px-4" size="sm" variant="success">Let's Go</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SAVE_USER_OPTIONS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'dashboard-tutorial-modal',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      neverShowAgain: false,
      activeStepIndex: 0,
      steps: [
        {
          id: 1,
          title: 'Overview',
          description:
            'The Complete Suite to Discover, Compose, Create, Plan, Analyze and Automate your Content Strategy.',
          image: 'video-placeholder.png',
          video: 'https://www.youtube.com/embed/h0jA4L5MtS0?si=4-eLffn2c-edK30S',
        },
        {
          id: 2,
          title: 'Navigate to your brand dashboard',
          description:
            'Find, analyze and share top performing content for any topic or industry. You can also find full content articles as well find  GIF’s, Images, Quotes all with a few searches…',
          image: 'content-discovery.png',
          video: 'https://www.youtube.com/embed/RpsxzZTe7dE?si=M-WCBATUznuCpgu3',
        },
        {
          id: 3,
          title: 'Multi-Channel Composer',
          description:
            'Compose fresh content for your blog or social media from an intuitive editor. Embed content from your favorite sources and find Articles, GIFs, Images, Quotes and more.',
          image: 'composer.png',
          video: 'https://www.youtube.com/embed/R20MXZ2i7Gk',
        },
        {
          id: 4,
          title: 'Planner',
          description:
            'Plan, schedule content for all your marketing channels from a single place using our robust Planner Calender and campaign tools',
          image: 'content-planner.png',
          video: 'https://www.youtube.com/embed/vEoR8z3FB2g',
        },
        {
          id: 5,
          title: 'Automation',
          description:
            'Automate your content discovery and publishing with ready to launch triggers and automation templates',
          image: 'automation.png',
        },
        {
          id: 6,
          title: 'Analytics',
          description: 'See the Numbers and get a statical view of your social media and content performance',
          image: 'video-placeholder.png',
          video: 'https://www.youtube.com/embed/UhKn4RH1jns',
        },
      ],
    };
  },

  computed: {
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    ...mapState('auth', {
      user: state => state.user,
      options: state => state.user.options,
    }),
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),

    handleNext(index = false) {
      const nextIndex = index !== false ? index : this.activeStepIndex + 1;

      if (nextIndex == this.steps.length) {
        this.activeStepIndex = 0;
      } else {
        this.activeStepIndex = nextIndex;
      }
    },

    handlePrev() {
      const nextIndex = this.activeStepIndex - 1;

      if (nextIndex < 0) {
        this.activeStepIndex = this.steps.length - 1;
      } else {
        this.activeStepIndex = nextIndex;
      }
    },

    close() {
      this.$bvModal.hide('DashboardTutorialModal');
    },

    handleOnHide() {
      if (this.neverShowAgain) {
        this.$apollo
          .mutate({
            mutation: SAVE_USER_OPTIONS_MUTATION,
            variables: {
              options: {
                ...this.options,
                onboarding: {
                  ...this.options.onboarding,
                  dashboard: false,
                },
              },
            },
            context: {
              uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
            },
          })
          .then(({ data }) => {
            this.updateUser({ user: data.user });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.dashboard-tutorial-modal {
  .modal-header {
    background-color: $primary;
    color: $white;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    font-weight: bold;
    padding: 0.7rem 1rem;

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .panel-header-button {
        .icon {
          fill: #fff;
        }
      }
    }
  }
  .modal-content {
    border-radius: 0.4rem;
    border: none;

    .modal-body {
      padding: 0;
    }

    .navigation {
      background-color: $light;
      border-right: 2px solid $gray-200;

      ul.tutorial-nav {
        list-style: none;
        padding: 0;
        margin-top: 30px;

        li {
          padding: 10px;
          cursor: pointer;

          .tutorial-nav-active-indicator {
            height: 5px;
            width: 90px;
            background-color: darken($gray-100, 10);
            border-radius: 10px;

            &.active {
              background-color: $primary;
            }
          }
        }
      }
    }
    .main-content {
      min-height: 510px;

      .btn-arrow {
        color: $gray-500;
      }

      .main-text {
        font-size: 0.9rem;
      }

      .video-player-wrap {
        min-height: 250px;
        width: 100%;
        background-color: $gray-200;
      }
    }
  }
  .modal-footer {
    .btn {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
</style>
