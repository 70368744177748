<template>
  <section class="has-topwizard ff-sfpro">
    <!-- hero section -->
    <div style="position: relative; ">
      <div class="absolute top-0 left-0 px-5 py-5 w-full h-full">
        <button @click="goBack" class="bg-white rounded-md p-2">
          Go back
        </button>
      </div>
      <div class="flex ml-5 -mt-5 items-center justify-center">
        <img style="object-fit: contain; " src="@/assets/images/ai/home2.png" alt="" />
      </div>
      <div class="absolute top-32 left-0 text-center w-full h-full">
        <h1 style="font-size: 4rem; font-weight: bold; line-height: 5rem;">
          AI Social Agents<br />
          Portal
        </h1>
        <p class="text-center text-base py-4">
          Just tell your agent your goal, and it will plan, create, <br />
          and execute campaigns across all your social media platforms perpetually <br />
          driving revenue while you sleep. Work for Blogs as well.
        </p>
        <div class="d-flex justify-content-center">
          <button
            @click="openCreateAICampaignModal"
            class="pr-5 pl-3 py-3 flex flex-row gap-3 items-center shadow"
            style="background: linear-gradient(to right, #8dbcb0 5%, #c6ef87 50%, #e3e082 80%); border-radius: 10rem;  border: none; font-weight: 500; font-size: 20px;"
          >
            <svg-icon name="campaign" class="mr-1" size="lg" />
            <span class="title">Create AI Campaign </span>
          </button>
        </div>
        <div style="position: absolute; top: 15%; left: 24%">
          <img style="object-fit: contain; margin-left: 10rem;" src="@/assets/images/ai/recruit.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <TopWizard :goBackHandler="goBackHandler" goBackText="Automation"> </TopWizard> -->

    <div class="my-5 mx-5">
      <h4 class="m-0 d-flex align-items-center justify-content-between">
        Campaigns

        <!-- <b-button :to="{ name: 'automation.create' }" variant="success" size="sm" class="px-4" pill>
          <svg-icon name="plus" class="mr-1" />
          Create new
        </b-button> -->
        <!-- <p>Filter</p> -->
      </h4>

      <!-- <div class="d-flex align-items-center mt-5">
        <b-dropdown
          v-if="selectedIds.length"
          variant="clear"
          text="Select action"
          toggle-class="shadow-sm bg-white px-4"
          class="custom-dropdown mb-2"
          right
        >
          <b-dropdown-item @click="promptConfirmStatusChange('activate')">Activate</b-dropdown-item>
          <b-dropdown-item @click="promptConfirmStatusChange('deactivate')">Deactivate</b-dropdown-item>
          <b-dropdown-item @click="promptConfirmDeleteAutomation()">Delete</b-dropdown-item>
        </b-dropdown>

        <div class="d-flex ml-auto">
          <DropdownAccountChooser type="social" @change="handleAccountFilterChange" />

          <div class="auto-campaign-search-wrap">
            <b-form-input
              type="text"
              placeholder="Search for campaign..."
              class="auto-campaign-search shadow-sm border-0"
              autocomplete="off"
              @keydown.enter="refetchAutomations"
            />
            <b-button variant="clear" size="sm" @clcik="refetchAutomations" class="input-icon text-primary">
              <svg-icon name="search" />
            </b-button>
          </div>
        </div>
      </div> -->

      <div class=" shadow-sm p-3">
        <div class="p-5 text-center" v-if="$apollo.queries.automations.loading"><b-spinner variant="primary" /></div>
        <b-table
          v-else-if="automations.length"
          borderless
          :fields="fields"
          :items="
            automations.map(a => ({ ...a, aiAgent: { name: a.aiAgentName, image: a.aiAgentImage }, busy: false }))
          "
          primary-key="id"
          thead-class=" border-bottom text-center"
          thead-th-class="gradient-text"
          tbody-class="text-center "
          :row-class="getRowClass"
        >
          <!-- A custom formatted header cell for field 'check' -->
          <template v-slot:head(check)="data">
            <b-form-checkbox
              :checked="selectedIds.length === automations.length"
              @change="handleCheckAllChange"
              id="checkAll"
              name="checkAll"
            />
          </template>
          <template v-slot:cell(aiAgent)="data">
            <div v-if="data.item.aiAgent.name" class="d-flex align-items-center">
              <img
                :src="data.item.aiAgent.image || require('@/assets/images/image-unavailable.png')"
                class="rounded-circle"
                width="40"
                height="40"
              />
              <span class="ml-2">{{ data.item.aiAgent.name }}</span>
            </div>
          </template>
          <template v-slot:cell(check)="data">
            <b-form-checkbox v-model="selectedIds" :value="data.item.id" :id="`check${data.item.id}`" />
          </template>
          <template v-slot:cell(status)="data">
            <b-form-checkbox
              :checked="data.item.status === 'active'"
              @change="handleStatusChange($event, data.item)"
              name="check-button"
              class="automation-status-switch"
              switch
            />
          </template>
          <template v-slot:cell(accounts)="data">
            <PublishAccountHeads :accounts="data.item.publishAccounts" />
          </template>
          <template v-slot:cell(actions)="data">
            <div class="auto-list-acitons">
              <b-button v-if="data.item.busy" variant="clear" size="sm" class="mr-2 p-0">
                <b-spinner variant="primary" small />
              </b-button>
              <b-button
                :to="{
                  name: 'automation.posts',
                  params: { id: data.item.id, type: platformTypeToAdd || 'all', recipe: 'ai' },
                }"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="View Posts"
                class="d-inline-block mr-2"
              >
                <svg-icon name="list" class="mt-0" />
              </b-button>
              <b-button
                v-if="$route.params.recipe !== 'csv'"
                @click="editAutomation(data.item)"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="Edit"
                class="mr-2 p-0"
              >
                <svg-icon name="edit" class="mt-0" />
              </b-button>
              <b-button
                v-if="$route.params.recipe !== 'csv'"
                @click="duplicateAutomation(data.item)"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="Duplicate"
                class="mr-2 p-0"
              >
                <svg-icon name="copy" class="mt-0" />
              </b-button>
              <b-button
                @click="promptConfirmDeleteAutomation(data.item)"
                variant="clear"
                size="sm"
                v-b-tooltip.hover
                title="Remove"
                class="p-0"
              >
                <svg-icon name="trash" class="mt-0" />
              </b-button>
            </div>
          </template>
        </b-table>
        <div v-else class="p-5 text-center">
          <img class="pt-3" width="200" src="@/assets/images/lost.svg" alt="" />
          <h5 class="w-75 mx-auto my-4">No results found</h5>

          <b-button @click="openCreateAICampaignModal" size="sm"
            ><svg-icon name="plus" class="mr-1" /> Create AI Campaign</b-button
          >
        </div>
      </div>
    </div>

    <!-- Create AI Campaign Modal -->
    <b-modal
      id="customModal"
      ref="CreateAICampaignModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
      centered
    >
      <div class="py-3 main-container">
        <!-- steps indicator section -->
        <div class="d-flex align-items-center justify-content-between">
          <div style="gap: 4rem;" class="d-flex  mb-3">
            <div v-for="(step, index) in steps" :key="index" @click="handleStepClick(index + 1)">
              <div
                :class="{
                  active: currentStep === index + 1,
                  completed: currentStep > index + 1,
                }"
                class="rounded-circle step-circle  bg-light  me-2 p-2 text-center fw-bold  custom-shadow "
                style="width: 40px; height: 40px; cursor: pointer; "
              >
                <svg-icon v-if="currentStep > index + 1" name="tick" size="md" />

                <span v-else>{{ index + 1 }}</span>
              </div>
            </div>
          </div>

          <div>
            <svg-icon @click="closeCreateAICampaignModal" name="close-alt" class="mr-1 cursor-pointer" size="4.5rem" />
          </div>
        </div>
        <div style="width: 35%; border-top: 3px dashed #f2f2f2; margin-top: -35px; margin-left: 5px;"></div>

        <transition name="fade-slide" mode="out-in">
          <!-- step 1 -->
          <div v-if="currentStep === 1" class="flex bg-gray-50 mt-5 p-5 rounded-xl">
            <div class="flex-grow-1">
              <p style="color: #7cc466;">Step 1</p>
              <h2 style="color: black; " class="ff-sfpro mb-3 fw-500">
                Select Agent Type And Account
              </h2>
              <div class="d-flex mt-4 justify-content-center">
                <img src="@/assets/images/ai/bot.png" alt="" />
              </div>
            </div>
            <div class="pl-5">
              <h5 class="text-gray-500 tracking-wider">CHOOSE AGENT TYPE</h5>
              <form class="d-flex" style="gap: 1rem;">
                <!-- Option 1 -->
                <div
                  class="radio-wrapper position-relative"
                  :style="{
                    background: '#ececec',
                    border: platformTypeToAdd === 'social' ? '2px solid #166534' : 'none',
                  }"
                >
                  <input
                    style="position: absolute; top: 10px ; right: 8px;"
                    type="radio"
                    id="social"
                    value="social"
                    :checked="platformTypeToAdd === 'social'"
                    @change="updatePlatformTypeToAdd('social')"
                    class="radio-input"
                  />
                  <label for="social" class="radio-label ">
                    <div class="d-flex" style="gap: 1rem;">
                      <img src="@/assets/images/ai/social_images/linkedin.png" alt="" />
                      <img src="@/assets/images/ai/social_images/facebook.png" alt="" />
                      <img src="@/assets/images/ai/social_images/ig_icon.png" alt="" />
                      <img src="@/assets/images/ai/social_images/x.png" alt="" />
                    </div>
                    Social Media Agent
                  </label>
                </div>
                <!-- Option 2 -->
                <div
                  class="radio-wrapper position-relative"
                  :style="{
                    background: '#ececec',
                    border: platformTypeToAdd === 'blog' ? '2px solid #166534' : 'none',
                  }"
                >
                  <input
                    style="position: absolute; top: 10px ; right: 8px;"
                    type="radio"
                    id="blog"
                    value="blog"
                    :checked="platformTypeToAdd === 'blog'"
                    @change="updatePlatformTypeToAdd('blog')"
                    name="options"
                    class="radio-input"
                  />
                  <label for="blog" class="radio-label ">
                    <div class="d-flex" style="gap: 1rem;">
                      <img src="@/assets/images/ai/social_images/wordpress.png" alt="" />
                    </div>
                    Blog Post Agents
                  </label>
                </div>
              </form>

              <div class=" mt-4">
                <div>
                  <!-- Text Area Section -->
                  <div class="position-relative">
                    <p style="position: absolute; top: 5px; left: 16px; font-size: 14px; color: #52c249;">
                      Enter Campaign Name
                    </p>
                    <div class="text-area-container ">
                      <b-form-group label-for="campaign-name">
                        <b-form-input
                          class="input pt-10 pb-4"
                          id="campaign-name"
                          :value="campaignName"
                          @input="updateCampaignName"
                          autocomplete="off"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <!-- Account Selection Section -->
                  <div class="">
                    <div class="account-section">
                      <h5 class="text-gray-500 tracking-wider">CHOOSE ACCOUNT</h5>

                      <div class="account-icons">
                        <AddPublishAccount tag="box" :queryType="queryType" />

                        <SocialAccountChooser :selectedAccounts="selectedAccounts" />
                      </div>
                    </div>
                  </div>
                  <div class="button-container" style="margin-top: 1rem;">
                    <button :disabled="!canProceedToStep2" style=" border-radius: 16px;  " @click="ProceedToNextStep()">
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- step 2 -->
          <div v-if="currentStep === 2" class="bg-light ff-sfpro rounded-md p-4 pb-6" style="margin-top: 2rem;">
            <div class="row  p-3 ">
              <section class="col-lg-6 col-12 col-md-12">
                <div class="mb-4">
                  <p style="color: #7cc466;" class="!mb-0">Step 2</p>
                  <h2 style="color: black;">
                    Personalize Your Agent
                  </h2>
                </div>
                <div class="relative" style="max-width: 400px">
                  <p class="top-2 left-3 tracking-wider absolute text-xs" style="color: #52c249;">
                    Name Your Agent
                  </p>
                  <div class="text-area-container ">
                    <b-form-group class="" label-for="agent-name">
                      <b-form-input
                        class="input pt-10 pb-4  w-full"
                        id="agent-name"
                        :value="aiAgentName"
                        @input="updateAiAgentName"
                        autocomplete="off"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </section>
              <section class="col-lg-6 col-12 col-md-12">
                <div
                  v-if="
                    (selectedAiAvatar != undefined && avatars[selectedAiAvatar]) || selectedAiAvatar?.includes('http')
                  "
                  class="flex items-center justify-center"
                >
                  <div
                    class="size-48 shadow cursor-pointer transition-default origin-center rounded-1/2"
                    :style="avatars[selectedAiAvatar]?.style"
                  >
                    <img
                      :src="avatars[selectedAiAvatar]?.image || selectedAiAvatar"
                      alt=""
                      class="w-full h-full object-cover rounded-md"
                    />
                  </div>
                </div>
              </section>
            </div>
            <div class="px-3">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="text-gray-500 tracking-wider">CHOOSE AI AVATAR</h5>
                <!-- <p>aiAgentName: {{ selectedAiAvatar }}</p> -->
                <input type="file" ref="fileInput" style="display: none" @change="handleFileSelection" />
                <button @click="selectFile" class="upload_avatar_btn">Upload Avatar</button>
              </div>
              <div class="flex flex-wrap gap-5 mt-3 ">
                <div
                  v-for="(avatar, index) in avatars"
                  :key="index"
                  class="size-16 cursor-pointer relative transition-default hover:transform-scale-110 origin-center rounded-1/2"
                  :class="{ 'border-2 border-white shadow': index === selectedAiAvatar }"
                  :style="avatar.style"
                >
                  <img
                    :src="avatar.image"
                    :class="{ selected: index === selectedAiAvatar }"
                    @click="selectAvatar(index)"
                    style="object-fit: cover;  border-radius: 50%;"
                    alt=""
                    class="avatar w-full h-full"
                  />

                  <div v-if="index === selectedAiAvatar" class="absolute -top-1 -right-1">
                    <div
                      class="w-5 h-5 flex items-center justify-center rounded-full bg-white bg-opacity-30 backdrop-blur-sm"
                    >
                      <b-icon icon="check" size="md" variant="success"></b-icon>
                    </div>
                  </div>
                </div>
                <!-- <div style="width: 70px; height: 70px; border: 2px solid gray; border-radius: 50%;"></div> -->
              </div>
              <div class="button-container" style="margin-top: 2rem;">
                <button :disabled="!canProceedToStep3" style=" border-radius: 16px;  " @click="ProceedToNextStep()">
                  Proceed
                </button>
              </div>
            </div>
          </div>

          <!-- step 3 -->
          <div v-if="currentStep === 3" class=" rounded-md " style="margin-top: 2rem; background: #fafafa;">
            <div class="flex-grow-1 ff-sfpro p-4 ">
              <p class="!mb-0" style="color: #7cc466;">Step 3</p>

              <div class="d-flex align-items-center justify-content-between">
                <h2 style="color: black;">
                  Choose Your Campaign Goals
                </h2>
                <div class="d-flex  " style="align-items: center; gap: 5px; ">
                  <div
                    style="width: 40px; height: 40px; border-radius: 50%; background: #1b4332;"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-icon icon="plus" variant="white"></b-icon>
                  </div>
                  <span style="color: #1b4332;">Frequency</span>
                </div>
              </div>
            </div>

            <div class="container pb-6 px-4">
              <div style="background: #eeeeee; border-radius: 28px;" class="container bg-gray-300 ">
                <div class="main-chat-container">
                  <!-- Chat Section -->
                  <div class=" chat-section">
                    <div class="chat-container">
                      <!-- Chat Box -->
                      <div ref="chatContainer" class="chat-box-container py-3 pr-2">
                        <div v-for="(message, index) in chatMessages" :key="index" class="chat-box">
                          <!-- Messages -->

                          <div v-if="message.sender === aiAgentName" class="message ff-sfpro user1">
                            <div class="d-flex align-items-center " style="gap: 8px; margin-bottom: 12px;">
                              <div
                                class="d-flex align-items-center justify-content-center rounded-1/2 w-8 h-8 overflow-hidden"
                                :style="avatars[0]?.style"
                              >
                                <img
                                  style="width: 100%; height: 100%; object-fit: contain;"
                                  :src="message.avatar"
                                  alt=""
                                />
                              </div>
                              <span style="font-weight: bold; color:black;">{{ message.sender }}</span>
                              <span>{{ formatTimestamp(message.timestamp) }}</span>
                            </div>
                            <p v-html="message.content" style="margin-bottom: 0;" class="message-content"></p>
                          </div>
                          <div v-if="message.sender === accountNames.name" class="message ff-sfpro user2">
                            <div class="d-flex align-items-center " style="gap: 8px; margin-bottom: 12px;">
                              <div
                                class="d-flex align-items-center rounded-1/2 w-8 h-8 overflow-hidden justify-content-center"
                              >
                                <img
                                  v-if="accountNames?.imageUrl"
                                  style="width: 100%; height: 100%; object-fit: contain;"
                                  :src="accountNames.imageUrl"
                                  alt=""
                                />
                                <img
                                  v-else
                                  style="width: 100%; height: 100%; object-fit: contain;"
                                  :src="
                                    user.photo ||
                                      `https://ui-avatars.com/api/?name=${[user.firstName, user.lastName]
                                        .filter(e => e)
                                        .join(' ')}&background=random`
                                  "
                                  alt=""
                                />
                              </div>
                              <span style="font-weight: bold; color:black;">{{
                                message.sender || [user.firstName, user.lastName].filter(e => e).join(' ')
                              }}</span>
                              <span>{{ formatTimestamp(message.timestamp) }}</span>
                            </div>
                            <p style="text-align: justify;">
                              {{ message.content }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <section>
                        <!-- Suggested Messages -->
                        <!-- <div class="overflow-x-auto suggested-wrapper text-right ">
                          <div class="suggested-messages">
                            <button
                              v-for="(response, index) in suggestedResponses"
                              :key="index"
                              @click="sendResponse(response)"
                              class=""
                            >
                              {{ response }}
                            </button>
                          </div>
                        </div> -->
                        <!-- Input Area -->
                        <div class="pr-3">
                          <div class="input-area rounded-xl bg-#f8f8f8">
                            <div class="chat-area">
                              <textarea
                                style="border-radius: 16px; border: 1px solid #eee; resize: none;"
                                class="w-full p-3 text-sm"
                                rows="2"
                                :disabled="lockChat"
                                v-model="userResponse"
                                :placeholder="lockChat ? 'Click on proceed to continue' : 'Type a message here...'"
                              ></textarea>
                              <div>
                                <button
                                  @click="sendResponse(userResponse)"
                                  :disabled="lockChat"
                                  style="background: #52c249; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); transition: all 0.3s ease;"
                                  class="size-48 shadow-md d-flex align-items-center justify-content-center"
                                  onmouseover="this.style.transform='translateY(-2px)'; this.style.boxShadow='0 6px 12px rgba(0, 0, 0, 0.15)'"
                                  onmouseout="this.style.transform=''; this.style.boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'"
                                  onmousedown="this.style.transform='translateY(1px)'; this.style.boxShadow='0 2px 4px rgba(0, 0, 0, 0.1)'"
                                >
                                  <i class="bi bi-send"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="button-container py-3">
                          <button
                            :disabled="!hasUserResponded"
                            style=" border-radius: 16px;  "
                            @click="ProceedToNextStep()"
                          >
                            Proceed
                          </button>
                        </div>
                      </section>
                    </div>
                  </div>

                  <!-- Second Section -->
                  <div style="border-left: 1px solid #d8d8d8; flex: none; max-width: 300px;" class="w-full">
                    <h5 class="mb-4 mt-3 pl-3 text-gray-500">
                      RESPONSE
                    </h5>
                    <div class="p-3 response">
                      <!-- Question and Answer -->
                      <div class="mb-4">
                        <p style="font-size: 16px;" class=" text-dark">Campaign Name</p>
                        <button class="p-2">
                          {{ campaignName }}
                        </button>
                      </div>
                      <div class="mb-4">
                        <p style="font-size: 16px;" class=" text-dark">What is your Campaign type?</p>
                        <button class="p-2">{{ platformTypeToAdd }} Campaign</button>
                      </div>
                      <div class="mb-4">
                        <p style="font-size: 16px;" class=" text-dark">Choosen Accounts</p>
                        <div style="flex-wrap: wrap;" class="d-flex">
                          <button v-for="(account, index) in selectedAccounts" :key="index" class="p-2   ">
                            {{ account.name }}
                          </button>
                        </div>
                      </div>
                      <div class="mb-3">
                        <p style="font-size: 16px;" class="text-dark">Campaign Goals</p>
                        <div class="goals-container">
                          <div
                            class="user_messages"
                            v-for="(message, index) in chatMessages.filter(m => m.sender == accountNames.name)"
                            :key="index"
                          >
                            <button v-if="message.sender === accountNames.name" class="p-2">
                              {{ message.content }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- step 4 -->
          <div v-if="currentStep === 4" class="bg-light ff-sfpro rounded-md " style="margin-top: 2rem;">
            <div class="flex-grow-1  p-4 ">
              <p style="color: #7cc466;">Step 4</p>
              <div class="d-flex align-items-center justify-content-between">
                <h2 style="color: black;">
                  Set Campaign Frequency
                </h2>
                <!-- <div class="d-flex  " style="align-items: center; gap: 5px; ">
                  <div
                    style="width: 40px; height: 40px; border-radius: 50%; background: #1b4332;"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span style="color: white;">+</span>
                  </div>
                  <span style="color: #1b4332;">Frequency</span>
                </div> -->
              </div>
            </div>
            <div class="container px-4 pb-4">
              <div class=" container border" style="background: #eeeeee; border-radius: 12px;">
                <div style="display: flex;">
                  <div class="chat-section">
                    <div class="p-3 my-4">
                      <div>
                        <AutomationScheduleStep :noBg="true" />
                        <div class="button-container" style="margin-top: 4rem;">
                          <button style=" border-radius: 16px;  " @click="proceedToSaveAutomation">
                            Proceed to Campaign
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Second Section -->
                  <div style="border-left: 1px solid #d8d8d8; flex: none; max-width: 350px;" class="">
                    <h5 class="text-gray-500 p-3">
                      RESPONSE
                    </h5>
                    <div class="p-3 response">
                      <!-- Question and Answer -->
                      <div class="mb-3">
                        <p style="font-size: 16px; margin-bottom: 0 !important;" class="text-dark">Campaign Name</p>
                        <button class="p-2   ">
                          {{ campaignName }}
                        </button>
                      </div>
                      <div class="mb-3">
                        <p style="font-size: 16px; margin-bottom: 0 !important;" class=" text-dark">
                          What is your Campaign type?
                        </p>
                        <button class="p-2">{{ platformTypeToAdd }} Campaign</button>
                      </div>
                      <div class="mb-3">
                        <p style="font-size: 16px; margin-bottom: 0 !important;" class=" text-dark">Choosen Accounts</p>
                        <div style="flex-wrap: wrap;" class="d-flex">
                          <button v-for="(account, index) in selectedAccounts" :key="index" class="p-2   ">
                            {{ account.name }}
                          </button>
                        </div>
                      </div>
                      <div class="mb-3">
                        <p style="font-size: 16px;" class=" text-dark">Campaign Goals</p>
                        <div
                          v-for="(message, index) in chatMessages.filter(m => m.sender == accountNames.name)"
                          :key="index"
                        >
                          <button v-if="message.sender === accountNames.name" class="p-2   ">
                            {{ message.content }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </b-modal>

    <!-- genaration campaign loading modal -->

    <div
      v-if="isLoading"
      class="modal show d-block"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loadingModalLabel"
      aria-hidden="true"
      style="background-color: rgba(0,0,0,0.5);"
    >
      <div class="modal-dialog modal-dialog-centered " role="document">
        <div style="border-radius: 3rem; padding: 1rem;" class="modal-content border">
          <div class="modal-body">
            <p style="font-size: 24px;" class="text-dark">Creating Campaign</p>
            <div class="content">
              <!-- Section 1 -->
              <div>
                <div class="profile-section">
                  <div class="profile-wrapper">
                    <div class="outer-circle beeping">
                      <div class="inner-circle">
                        <img :src="loadingAvatar" alt="Profile Image" class="profile-image" />
                      </div>
                    </div>
                  </div>
                </div>
                <p class="username">{{ aiAgentName }}</p>
              </div>

              <!-- Section 2 -->
              <div class="details-section">
                <h5 class="text-gray-green tracking-wider">CAMPAIGN NAME</h5>
                <p style="font-size: 14px; font-weight: bold;" class="campaign-value ">
                  {{ campaignName }}
                </p>
                <h5 class="text-gray-green tracking-wider">GOAL</h5>
                <div v-for="(message, index) in chatMessages.filter(m => m.sender == accountNames.name)" :key="index">
                  <button
                    v-if="message.sender === aiAgentName"
                    style="background: white; margin-bottom: 1rem;"
                    class="upload_avatar_btn"
                  >
                    {{ message.content }}
                  </button>
                </div>
                <p class="text-gray-green tracking-wider" style="margin-bottom: 5px; text-transform: uppercase;">
                  Account:
                </p>
                <div class="d-flex align-items-center " style="gap: 8px; margin-bottom: 12px;">
                  <div
                    class="d-flex align-items-center justify-content-center "
                    style="width: 30px; height: 30px;  border-radius: 50%; overflow: hidden;"
                  >
                    <img
                      style="width: 100%; height: 100%; object-fit: contain;"
                      src="@/assets/images/ai/social_images/wordpress.png"
                      alt=""
                    />
                  </div>
                  <span>Sucre Anderson</span>
                </div>

                <p class="status">Creating Campaign Tunnels......</p>
                <p class="status"> {{ loadingTexts[currentTextIndex] }}</p>

                <router-link 
                  :to="{
                    name: 'automation.posts',
                    params: { id: resultId, type: platformTypeToAdd || 'all', recipe: 'ai' },
                  }"
                  v-if="isReady"
                  class="btn btn-primary d-flex align-items-center justify-content-center"
                  style="margin-top: 1rem; padding: 0.5rem 1rem;"
                >
                  View Automation
                </router-link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="DeleteModal" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>
      <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>Delete Automation{{ !envokedForDelete ? '(s)' : '' }}?</h4>
        <p>
          Are you sure you would like to delete {{ !envokedForDelete ? 'the selected' : 'this' }} automation{{
            !envokedForDelete ? '(s)' : ''
          }}?
        </p>

        <b-button variant="light" class="mr-2" @click="cancelDeleteAutomation">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="deleteAutomation">Delete</b-button>
      </div>
    </b-modal>

    <b-modal ref="ChangeStatusModal" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>
      <div v-if="statusChangeLoading" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>{{ capitalize(envokedStatusAction) }} Automation(s)?</h4>
        <p>Are you sure you would like to {{ envokedStatusAction }} the selected automation(s)?</p>

        <b-button variant="light" class="mr-2" @click="cancelStatusChange">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="changeAutomationStatus()">{{
          capitalize(envokedStatusAction)
        }}</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import { debounce, capitalize } from 'lodash';
import TopWizard from '~/components/TopWizard';
import moment from 'moment';
import { AUTOMATIONS_QUERY } from '~/graphql/queries';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import AutomationMetaMixin from '~/mixins/AutomationMetaMixin';
import PublishAccountHeads from '~/components/PublishAccountHeads';
import SocialAccountChooser from '~/components/SocialAccountChooser';
import AutomationScheduleStep from '~/components/AutomationScheduleStep';
import AddPublishAccount from '~/components/AddPublishAccount';

import DropdownAccountChooser from '~/components/DropdownAccountChooser';
import { CHANGE_AUTOMATION_STATUS_MUTATION, REMOVE_AUTOMATION_MUTATION } from '~/graphql/mutations';

import { createNamespacedHelpers } from 'vuex';
import { can } from '~/utils/helpers';
import app from '~/main';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  components: {
    TopWizard,
    PublishAccountHeads,
    DropdownAccountChooser,
    SocialAccountChooser,
    AutomationScheduleStep,
    AddPublishAccount,
  },

  mixins: [AutomationMetaMixin],

  props: {
    // selectedAccounts: {
    //   type: Array,
    //   default: () => [],
    // },

    accounts: {
      type: Array,
      default: () => [],
    },

    queryType: {
      type: String,
      default: 'social',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadingTexts: [
        'Creating Campaign Tunnels',
        'Verifying Requests',
        'Generating Content',
        'Designing Graphics',
        'Optimizing Campaign Flow',
      ],
      currentTextIndex: 0,
      animationInterval: null,

      automations: [],
      loadingAvatar: null,
      selectedIds: [],
      envokedForDelete: null,
      isDeleting: false,
      resultId: null,

      envokedStatusAction: null,
      statusChangeLoading: false,
      isLoading: false,
      steps: [1, 2, 3, 4],
      // currentStep: 0,
      fetching: false,
      publishAccounts: [],
      selectedAccountType: '',
      lockChat: false,
      // selectedAccounts: [],
      upgradeModal: false,
      userResponse: '',
      isTyping: false,
      currentQuestion: 0,
      questions: [
        'What is your business type?',
        'What is the target audience?',
        'What is the primary goal of this campaign? (e.g., engagement, sales, brand awareness)',
        'What tone or style do you prefer for your posts? (e.g., professional, casual, humorous)',
        'What are the key topics or themes you want to focus on? (e.g., product features, customer stories, industry news)',
      ],
      suggestedResponses: [
        'Increase brand awareness',
        'Generate leads',
        'Boost website traffic',
        'Drive sales',
        'Improve customer engagement',
        'Build brand loyalty',
        'Grow social media following',
        'Increase product visibility',
        'Enhance customer service',
        'Promote special offers',
        'Share company updates',
        'Showcase customer testimonials',
      ],
      hasUserResponded: false,
      avatars: Array.from({ length: 23 }, (_, i) =>
        require(`@/assets/images/AIAgents/AISocialAgents (${i + 1}).png`),
      ).map(avatar => {
        return {
          image: avatar,
          selected: false,
          style: `background: linear-gradient(${Math.random() * 360}deg, #${Math.random()
            .toString(16)
            .slice(2, 8)}, #${Math.random()
            .toString(16)
            .slice(2, 8)});`,
        };
      }),
    };
  },

  computed: {
    ...mapState('createAutomation', {
      currentStep: state => state.currentStep,
      campaignName: state => state.campaignName,
      selectedAccounts: state => state.selectedAccounts,
      aiAgentName: state => state.aiAgentName,
      selectedAiAvatar: state => state.selectedAiAvatar,
      chatMessages: state => state.chatMessages,
    }),
    ...mapState('publishAccount', {
      platformTypeToAdd: state => state.platformTypeToAdd,
    }),

    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
    ]),

    isPermittedToUseUpgrade() {
      const user = app.$store.state.auth?.user;
      return user && can(user, 'bypass-subscription');
    },
    accountNames() {
      return this.selectedAccounts[1] || {};
    },

    myChats() {
      return this.chatMessages.filter(m => m.sender == this.accountNames.name) || [];
    },

    canProceedToStep2() {
      return this.selectedAccounts.length > 0 && this.campaignName.trim() !== '';
    },
    canProceedToStep3() {
      return this.aiAgentName.trim() && this.selectedAiAvatar != undefined;
    },

    fields() {
      const fields = [
        {
          key: 'check',
          class: 'text-left',
        },
        {
          key: 'aiAgent',
          label: 'AI Agent',
        },
        {
          key: 'status',
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'Goals',
        },
        {
          key: 'accounts',
        },
        {
          key: 'publishCount',
          label: 'Published Posts',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ];

      // We don't want to show "next refill time" field on csv automations
      // because they aren't refillable
      if (this.$route.params.recipe !== 'csv') {
        return [
          ...fields.slice(0, 4),
          {
            key: 'nextRefillTimeFormatted',
            label: 'Next refill time',
            sortable: true,
          },
          ...fields.slice(4),
        ];
      }

      return fields;
    },
    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
      listSearchInput: state => state.createAutomation.listSearchInput,
      listSelectedAccountIds: state => state.createAutomation.listSelectedAccountIds,
    }),
  },
  apollo: {
    automations: {
      query: AUTOMATIONS_QUERY,
      variables() {
        return {
          type: 'all',
          recipe: 'ai',
          workspace: this.workspace.id,
          search: this.listSearchInput,
          accountIds: this.listSelectedAccountIds,
        };
      },
    },
  },

  mounted() {
    window.Echo.channel('automations').listen('AutomationPlanned', automation => {
      if (this.$apollo.queries.automations && !this.$apollo.queries.automations.loading) {
        this.updateAutomation(automation.id, {
          busy: false,
          nextRefillTimeFormatted: automation.nextRefillTimeFormatted,
        });
      }
    });

    window.Echo.channel('automations').listen('AutomationPlanningStarted', automation => {
      if (this.$apollo.queries.automations && !this.$apollo.queries.automations.loading) {
        this.updateAutomation(automation.id, { busy: true });
      }
    });

    window.Echo.channel('automations').listen('AutomationPlanningFailed', automation => {
      if (this.$apollo.queries.automations && !this.$apollo.queries.automations.loading) {
        this.updateAutomation(automation.id, { busy: false });
      }
    });

    this.isLoading = false;
    this.isTyping = false;
    this.updateCurrentStep(1);
    this.clearMessages();
    this.reset();
    // this.fetchAvatars();
  },

  beforeDestroy() {
    window.Echo.leave('automations');
    clearInterval(this.animationInterval);
  },

  watch: {
    accounts(accounts) {
      this.publishAccounts = accounts;
    },

    currentStep(newStep) {
      if (newStep === 3) {
        this.addAiMessage();
      }
    },
  },

  methods: {
    ...mapActions('createAutomation', [
      'updateCampaignName',
      'clearMessages',
      'updateSelectedAccounts',
      'updateCurrentStep',
      'updateSelectedAiAvatar',
      'updateAiAgentName',
      'addMessage',
    ]),
    ...mapActions({
      updateCurrentWorkspace: 'workspace/updateCurrent',
    }),

    selectAvatar(avatar) {
      this.updateSelectedAiAvatar(avatar);
    },

    ...mapActions('publishAccount', ['updatePlatformTypeToAdd']),
    updateAccountType(type) {
      this.updateSelectedAccountType(type);
    },

    updateSelectedAccountType(type) {
      this.updatePlatformTypeToAdd(type);
    },

    addChatMessage(data) {
      this.addMessage(data);
      setTimeout(() => {
        this.scrollToBottom();
      }, 1500);
    },

    scrollToBottom() {
      const container = this.$refs.chatContainer;
      if (!container) return;

      container.scrollTo({
        top: container.scrollHeight + 20000,
        behavior: 'smooth',
      });
    },

    getRowClass(row, index) {
      return index % 2 === 0 ? 'bg-white' : 'bg-light';
    },
    openCreateAICampaignModal() {
      this.$refs.CreateAICampaignModal.show();
    },
    closeCreateAICampaignModal() {
      this.$refs.CreateAICampaignModal.hide();
    },
    proceedToCreateAICampaign() {
      this.closeCreateAICampaignModal();
      this.isLoading = true;
      // timeout to simulation campaign creation
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },

    // automation steps method
    ProceedToNextStep() {
      if (this.currentStep < 4) {
        this.updateCurrentStep(this.currentStep + 1);
      }
    },

    reset() {
      this.updateCurrentStep(1);
      this.updateCampaignName('');
      this.updateSelectedAccounts([]);
      this.updateSelectedAiAvatar(null);
      this.updateAiAgentName('');
      this.updatePlatformTypeToAdd('social');
      this.updateSelectedAccountType('');
      this.clearMessages();
    },

    // method to navigate to previous step
    handleStepClick(targetStep) {
      // Allow going back to any previous step
      if (targetStep < this.currentStep) {
        this.updateStep(targetStep);
        return;
      }

      // Validating the current step
      const isValid = this.validateCurrentStep();
      if (isValid) {
        this.updateStep(targetStep);
      } else {
        this.$notify({
          group: 'main',
          type: 'native-error',
          title: 'Incomplete step',
          text: 'Please complete the current step before proceeding.',
        });
      }
    },

    validateCurrentStep() {
      switch (this.currentStep) {
        case 1:
          return this.campaignName.trim() && this.selectedAccounts.length > 0;
        case 2:
          return this.agentName && this.selectedAiAvatar;
        case 3:
          if (!this.userResponded && this.myChats.length < 3) {
            return;
          }
        case 4:
          // Add your validation logic for step 4
          return true;
        default:
          return false;
      }
    },
    updateStep(step) {
      this.$store.dispatch('createAutomation/updateCurrentStep', step);
    },

    updateCampaignName(value) {
      this.$store.dispatch('createAutomation/updateCampaignName', value);
    },

    selectFile() {
      this.$refs.fileInput.click();
    },

    async handleFileSelection(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const res = await fetch(process.env.VUE_APP_API_ROOT + '/api/upload', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            body: formData,
          });

          const data = await res.json();

          this.updateSelectedAiAvatar(data.url);
        } catch (error) {
          console.log(error);

          alert('Error uploading file');
        }
      }
    },
    async fetchAvatars() {
      try {
        const response = await axios.get('https://app.socialagency360.com/api/ai_agents');
        this.avatars = response.data;
        console.log('fetched avatars');
      } catch (error) {
        console.error('Error fetching avatars:', error);
      }
    },

    // fetchAccounts() {
    //   if (!this.selectedAccountType) return;
    //   if (this.accounts.length < 1) {
    //     this.fetching = true;
    //     console.log(this.selectedAccountType);

    //     this.$apollo
    //       .query({
    //         query: PUBLISH_ACCOUNTS_QUERY,
    //         variables: {
    //           workspace: this.workspace.id,
    //           type: this.selectedAccountType,
    //         },
    //       })
    //       .then(({ data }) => {
    //         this.publishAccounts = data.publishAccounts || [];
    //         this.$store.dispatch('publishAccount/updateAccounts', data.publishAccounts);

    //         this.fetching = false;
    //       })
    //       .catch(() => {
    //         this.fetching = false;
    //       });
    //   } else {
    //     this.publishAccounts = this.accounts;
    //   }
    // },

    sendResponse(content) {
      if (!content) return;

      this.addChatMessage({
        sender: this.accountNames.name,
        content,
        timestamp: new Date().toISOString(),
      });

      this.scrollToBottom();

      this.hasUserResponded = true;
      this.userResponse = '';

      this.setTyping(true);
      setTimeout(() => {
        const question = this.questions[this.currentQuestion + 1];

        if (question) {
          this.addChatMessage({
            sender: this.aiAgentName,
            avatar: this.getAiAvatar(),
            content: this.bolder(question),
            timestamp: new Date().toISOString(),
          });
          this.currentQuestion++;
        } else {
          this.addChatMessage({
            sender: this.aiAgentName,
            avatar: this.getAiAvatar(),
            lock: true,
            content: 'Thank you for your response!<br/> Lets proceed to review your responses.',
            timestamp: new Date().toISOString(),
          });
          this.lockChat = true;
          this.currentQuestion = 0;
        }

        this.scrollToBottom();
      }, 1000);
    },

    setTyping(isTyping) {
      this.isTyping = isTyping;
    },

    getAiAvatar() {
      if (this.selectedAiAvatar) {
        return typeof this.selectedAiAvatar === 'number'
          ? this.avatars[this.selectedAiAvatar].image
          : this.selectedAiAvatar;
      }
      return null;
    },

    addAiMessage() {
      const personalizedMessage = `Hi ${this.user?.firstName}, Welcome!! <br/> Let me walk you through the process. Let's start by asking you a few questions.`;

      this.addChatMessage({
        sender: this.aiAgentName,
        avatar: this.getAiAvatar(),
        content: personalizedMessage,
        timestamp: new Date().toISOString(),
      });

      this.setTyping(true);

      setTimeout(() => {
        this.currentQuestion = 0;
        this.addChatMessage({
          sender: this.aiAgentName,
          avatar: this.getAiAvatar(),
          content: this.bolder(this.questions[0]),
          timestamp: new Date().toISOString(),
        });
      }, 1000);
    },

    bolder(str) {
      return '<b class="text-black">' + str + '</b>';
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return moment(date)
        .format('hh:mm A')
        .toLowerCase();
    },

    startTextAnimation() {
      this.animationInterval = setInterval(() => {
        if (this.currentTextIndex >= this.loadingTexts.length - 1) {
          this.currentTextIndex = 0;
        } else {
          this.currentTextIndex++;
        }
      }, 1000);
    },

    async proceedToSaveAutomation() {
      // this.savingAutomation = true;
      // this.proceedToSave = true;
      this.startTextAnimation();
      this.loadingAvatar = this.getAiAvatar()
      let hasSucceded = false;
      this.isReady = false;
      this.closeCreateAICampaignModal();
      this.isLoading = true;
      return this.$store
        .dispatch('createAutomation/saveAutomation', {
          workspace: this.workspace.id,
          type: this.platformTypeToAdd,
          aiAgentName: this.aiAgentName,
          $aiAgentImage: this.getAiAvatar(),
          aiPrompt: `Generate social media post ideas for a ${this.myChats[0]?.content} targeting ${
            this.myChats[1]?.content
          }. The campaign aims to achieve ${this.myChats[2]} with a ${
            this.myChats[3]?.content
          } tone. Focus on topics such as ${this.myChats.map(item => `"${item.content}"`).join(', ')}.`,
          recipe: 'ai',
          $selectedAccounts: this.selectedAccounts?.map(account => {
            return {
              accountId: account.id,
              platform: account.type?.platform,
              publishVariant: account.type?.platformType,
              publishAuthorId: account.id,
              publishCategoryIds: [],
              publishStatus: 'pending',
            };
          }),
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Automation saved',
            text: 'Your automation is now being processed.',
          });
          this.isReady = true;
          hasSucceded = true;

          // const usedCount = parseInt(get(this.user, 'subscription.limitsUsage.automations', 0), 10);

          // this.$store.dispatch('auth/updateUser', {
          //   user: {
          //     ...this.user,
          //     subscription: {
          //       ...get(this.user, 'subscription', {}),
          //       limitsUsage: {
          //         ...get(this.user, 'subscription.limitsUsage', {}),
          //         automations: usedCount + 1,
          //       },
          //     },
          //   },
          // });

          // const count = parseInt(get(this.workspace, 'limitsUsage.automations', 0), 10);

          // this.updateCurrentWorkspace({
          //   ...this.workspace,
          //   limitsUsage: {
          //     ...get(this.workspace, 'limitsUsage', {}),
          //     automations: count + 1,
          //   },
          // });

          this.reset();

          // this.isLoading = false;
        })
        .catch(e => {
          this.loading = 'error';

          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'Something went wrong while processing your request. Please try again.',
          });
        })
        .finally(() => {
          if (hasSucceded) {
            setTimeout(() => {
              this.isLoading = false;
              window.location.reload();
            }, 15000);
          } else {
            this.isLoading = false;
          }
        });

      // this.setCurrentStep(this.currentStep + 1);
    },

    viewAutomations() {
      this.$store.dispatch('createAutomation/reset');
      this.$router.push({ name: 'automation.list' });
    },

    goBackHandler() {
      this.$router.push({ name: 'automation.home' });
    },
    goBack() {
      window.history.back();
    },

    updateAutomation(automationId, updates) {
      const data = this.readQueryData();

      const index = data.automations.findIndex(a => automationId === a.id);
      if (index !== -1) {
        data.automations.splice(index, 1, {
          ...data.automations[index],
          ...updates,
        });

        this.writeQueryData(data);
      }
    },

    readQueryData() {
      const store = this.$apollo.getClient();
      // Read the data from our cache for this query.
      return store.readQuery({
        query: AUTOMATIONS_QUERY,
        variables: {
          workspace: this.workspace.id,
          type: 'all',
          recipe: this.$route.params.recipe,
          search: this.listSearchInput,
          accountIds: this.listSelectedAccountIds,
        },
      });
    },

    writeQueryData(data) {
      const store = this.$apollo.getClient();
      store.writeQuery({
        query: AUTOMATIONS_QUERY,
        variables: {
          workspace: this.workspace.id,
          type: 'all',
          recipe: this.$route.params.recipe,
          search: this.listSearchInput,
          accountIds: this.listSelectedAccountIds,
        },
        data,
      });
    },

    handleCheckAllChange(isChecked) {
      if (isChecked) {
        this.selectedIds = this.automations.map(auto => auto.id);
      } else {
        this.selectedIds = [];
      }
    },

    promptConfirmStatusChange(status) {
      this.envokedStatusAction = status;

      this.$refs.ChangeStatusModal.show();
    },

    cancelStatusChange() {
      if (this.envokedStatusAction) {
        this.envokedStatusAction = null;
      }

      this.$refs.ChangeStatusModal.hide();
    },

    // We use debounce to limit unnecessary calls to the server
    // for example when you toggle status on and immediately toggle it off again
    handleStatusChange: debounce(function(isChecked, automation) {
      const status = isChecked ? 'active' : 'inactive';

      if (automation.status === status) {
        return;
      }

      this.changeAutomationStatus(status, automation);
    }, 1000),

    async changeAutomationStatus(status = null, automation = null) {
      if ((!status && !this.envokedStatusAction) || (!automation && !this.selectedIds.length)) {
        return;
      }

      let automationStatus = status;
      if (this.envokedStatusAction) {
        automationStatus = this.envokedStatusAction === 'activate' ? 'active' : 'inactive';
      }

      // Let's not send the automations that already has our target status
      const ids = this.selectedIds.filter(id => {
        const auto = this.automations.find(a => a.id === id);
        return auto && auto.status !== automationStatus;
      });

      if (ids.length || automation) {
        this.statusChangeLoading = true;

        await this.$apollo
          .mutate({
            mutation: CHANGE_AUTOMATION_STATUS_MUTATION,
            variables: {
              workspace: this.workspace.id,
              ids: automation ? [automation.id] : ids,
              status: automationStatus,
            },
            update: (store, { data: { changed } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: AUTOMATIONS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  search: this.listSearchInput,
                  accountIds: this.listSelectedAccountIds,
                },
              });
              changed.map(auto => {
                const index = data.automations.findIndex(a => auto.id === a.id);

                if (index !== -1) {
                  data.automations.splice(index, 1, auto);
                }
              });
              // Write our data back to the cache.
              store.writeQuery({
                query: AUTOMATIONS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  search: this.listSearchInput,
                  accountIds: this.listSelectedAccountIds,
                },
                data,
              });
            },
          })
          .then(() => {
            this.statusChangeLoading = false;
          })
          .catch(() => {
            this.statusChangeLoading = false;
            this.$refs.ChangeStatusModal.hide();

            Swal.fire({
              type: 'error',
              title: 'OOPS',
              text: 'An error occurred. Please try again',
            });
          });
      }

      if (this.selectedIds.length && !automation) {
        this.$refs.ChangeStatusModal.hide();
        this.selectedIds = [];
      }
    },

    handleAccountFilterChange(accountIds) {
      this.$store.dispatch('createAutomation/updateListSelectedAccounts', accountIds);
    },

    refetchAutomations(e) {
      this.$store.dispatch('createAutomation/updateListSearchInput', e.target.value);
    },

    editAutomation(automation) {
      this.$store.dispatch('createAutomation/sync', automation);

      this.$router.push({ name: 'automation.create' });
    },

    duplicateAutomation(automation) {
      this.$store.dispatch('createAutomation/sync', { ...automation, id: 0, name: `${automation.name} copy` });

      this.$router.push({ name: 'automation.create' });
    },

    promptConfirmDeleteAutomation(automation = null) {
      if (automation) {
        this.envokedForDelete = automation;
      }

      this.$refs.DeleteModal.show();
    },

    cancelDeleteAutomation() {
      if (this.envokedForDelete) {
        this.envokedForDelete = null;
      }

      this.$refs.DeleteModal.hide();
    },

    deleteAutomation() {
      if (!this.envokedForDelete && !this.selectedIds.length) {
        return;
      }

      this.isDeleting = true;

      this.$apollo
        .mutate({
          mutation: REMOVE_AUTOMATION_MUTATION,
          variables: {
            workspace: this.workspace.id,
            ids: this.envokedForDelete ? [this.envokedForDelete.id] : this.selectedIds,
          },
          update: (store, { data: { deleted } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: AUTOMATIONS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'all',
                recipe: this.$route.params.recipe,
                search: this.listSearchInput,
                accountIds: this.listSelectedAccountIds,
              },
            });
            if (deleted) {
              if (!this.envokedForDelete) {
                this.selectedIds.forEach(id => {
                  const index = data.automations.findIndex(a => id === a.id);

                  if (index !== -1) {
                    data.automations.splice(index, 1);
                  }
                });
              } else {
                const index = data.automations.findIndex(a => this.envokedForDelete.id === a.id);

                if (index !== -1) {
                  data.automations.splice(index, 1);
                }
              }
            }
            // Write our data back to the cache.
            store.writeQuery({
              query: AUTOMATIONS_QUERY,
              variables: {
                workspace: this.workspace.id,
                type: 'all',
                recipe: this.$route.params.recipe,
                search: this.listSearchInput,
                accountIds: this.listSelectedAccountIds,
              },
              data,
            });
          },
        })
        .then(() => {
          const usedCount = parseInt(this.user.subscription.limitsUsage.automations);
          this.$store.dispatch('auth/updateUser', {
            user: {
              ...this.user,
              subscription: {
                ...this.user.subscription,
                limitsUsage: {
                  ...this.user.subscription.limitsUsage,
                  automations: usedCount - 1,
                },
              },
            },
          });

          const count = parseInt(this.workspace.limitsUsage.automations);
          this.$store.dispatch('workspace/updateCurrent', {
            ...this.workspace,
            limitsUsage: {
              ...this.workspace.limitsUsage,
              automations: count - 1,
            },
          });

          this.$refs.DeleteModal.hide();
          this.isDeleting = false;
        })
        .catch(e => {
          console.dir(e);
          this.$refs.DeleteModal.hide();
          this.isDeleting = false;

          Swal.fire({
            type: 'error',
            title: 'OOPS!',
            text: 'Could not delete automation. Try again later.',
          });
        });
    },

    capitalize,
  },

  created() {
    console.log(this.chatMessages);
    console.log(this.selectedAccounts);
    console.log(this.user);
    console.log(this.workspace);
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

thead th {
  outline: 0;
}

.loading-status-wrapper {
  padding: 20px;
}

.loading-status-container {
  .loading-text {
    opacity: 0;
    height: 0;
    margin: 0;
    transform: translateY(-20px);
    transition: all 0.5s ease;
    color: #1b4332;
    
    &.active {
      opacity: 1;
      height: 24px;
      margin-bottom: 8px;
      transform: translateY(0);
    }
  }
}


.automation-status-switch {
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: $success;
          background-color: $success;
        }
      }
    }
  }
}

.auto-campaign-search-wrap {
  position: relative;

  .auto-campaign-search {
    font-size: 15px;

    &::placeholder {
      color: $gray-400;
    }
  }

  .input-icon {
    position: absolute;
    right: 3px;
    top: 4px;
    cursor: pointer;
    box-shadow: none;
  }
}

.auto-list-acitons {
  .btn {
    &:focus {
      box-shadow: none;
    }
  }
}
.gradient-text {
  background: linear-gradient(to right, #3ead02, #f9061e);
  // background: linear-gradient(180deg, #a2adab, #537063);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-white {
  background-color: white;
}

.bg-light {
  background-color: red; /* Light grey background */
}

#customModal .modal-dialog {
  max-width: 70%;
}
.custom-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.step-circle.active {
  border-bottom: 4px solid #4caf50;
}

.step-circle.completed {
  border-color: #4caf50;
  background-color: #4caf50;
  color: white;
}

.rounded-circle {
  border: 4px solid white;
  outline-color: #e3e082;
}
.main-container {
  padding: 8px 16px;
}
.radio-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.radio-input {
  display: none;
}

.radio-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  width: 250px;
  height: 100px;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;
  transition: border-color 0.3s ease;
}

.radio-label img {
  max-width: 100%;
  max-height: 70px;
  object-fit: contain;
}

// .radio-input:checked + .radio-label {
//   border-color: #007bff; /* Change border color on selection */
// }
.radio-input:checked + .radio-label::before {
  border-color: white;
  background-color: #1b4332;
}
.radio-label::before {
  content: '';
  position: absolute;
  top: 10px;
  right: 8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.text-area-container {
  width: 100%;
}
.text-area-container .input {
  border-radius: 1.25rem;
  border: 1px solid #ccc;
}
.account-section {
  margin-top: 2rem;
}

.account-icons {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  gap: 2rem;
  // margin-top: 8px;
}

.plus-icon {
  font-size: 24px;
  color: black;
}

.alphabet-icon {
  font-size: 16px;
  font-weight: bold;
  color: #343a40;
}
h5 {
  color: #c9c9c9;
  font-size: 16px;
}
.button-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.button-container button {
  background: #1b4332;
  padding: 10px 60px;
  border: none;
  color: white;
}
.button-container button.disabled,
.button-container button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #1c513b; /* Optional: Different background for disabled state */
}
.upload_avatar_btn {
  appearance: none;
  padding: 5px 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 18px;
  border: 2px solid #1c513b !important;
  color: #1b4332;
}
.main-chat-container {
  display: flex;
}
.chat-section {
  flex: 1;
}

.chat-container {
  border-radius: 8px;
  height: 550px;
  display: flex;
  flex-direction: column;
  margin-top: -2px;
}
.chat-box-container {
  overflow-y: auto;
  margin-right: -3px;
  height: 100%;
}

.chat-box {
  flex: 1;

  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  // border: 2px solid green;
}

.message {
  max-width: 60%;
  padding: 0.8rem;
  border-radius: 20px;
  font-size: 14px;
}

.message.user1 {
  background-color: #f8f8f8;
  color: gray;
  align-self: flex-start;
}

.message.user2 {
  background-color: #f8f8f8;
  color: gray;
  align-self: flex-end;

  p {
    margin-bottom: 0;
  }
}

.suggested-messages {
  display: inline-flex;
  max-width: 300px;
  gap: 0.5rem;
  padding-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.suggested-wrapper {
  &::-webkit-scrollbar {
    width: 13px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4caf50;
    border-radius: 6px;
    height: 5px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #45a049;
  }
}

.chat-box-container {
  overflow-y: auto;
  padding: 0;

  &::-webkit-scrollbar {
    width: 13px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4caf50;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #45a049;
  }
}

.suggested-messages button {
  white-space: nowrap;
  border: 1px solid rgba(128, 128, 128, 0.306);
  padding: 0.5em 1em;
  font-size: 12px;
  border-radius: 40px;
  opacity: 0.6;
}

.input-area > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 8px;
}
.chat-area {
  padding: 1rem;
}

.input-area button {
  border: none;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.content {
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: space-between;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.profile-wrapper {
  position: relative;
}

.outer-circle {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 12px solid #56cb4e;
  border-top-color: white;
  border-right-color: white;
}

.inner-circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  object-fit: cover;
}

.username {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: start;
  color: rgb(77, 72, 72);
}
.beeping {
  animation: beep 1.5s infinite;
}

.text-gray-green {
  color: #1d4532a0;
}

@keyframes beep {
  0%,
  20% {
    border-left-color: #56cb4e;
  }
  40% {
    border-top-color: #56cb4e;
  }
  60% {
    border-right-color: #56cb4e;
  }
  80% {
    border-bottom-color: #56cb4e;
  }
  100% {
    border-left-color: white;
  }
}

.details-section {
  flex: 2;
}

.status {
  font-size: 16px;
  color: #1b4332;
}

.selected {
  // border: 2px solid black;
}

.response button {
  background: #e0e0e0;
  color: #5d5d5d;
  padding: 0.3rem 1rem !important;
  border-radius: 2rem;
  font-size: 0.875rem;
  white-space: nowrap;
  margin-top: 5px;
  border: none !important;
  margin-left: 0;
}

.response p {
  margin-bottom: 0;
}
.goals-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-leave-active {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
