<template>
  <section class="has-topwizard">
    <!-- <TopWizard :goBackHandler="goBackHandler" goBackText="Automations" :sidebar="false"> </TopWizard> -->
    <h2 class="mx-5 pb-3" style="border-bottom: 3px solid #576f7f;">Automations</h2>
    <div class="my-5 mx-4 bg-light ">
      <div v-if="$apollo.queries.automation.loading" class="p-5 bg-white shadow-sm text-center">
        <b-spinner variant="primary" />
      </div>
      <div v-else>
        <div class=" py-3 d-flex px-4 gap-3 align-items-center">
          <div class="size-16 rounded-1/2 bg-white overflow-hidden">
            <img
              :src="automation.aiAgentImage || require('@/assets/images/ai/ai-assistant.png')"
              alt="ai image"
              style="object-fit: cover;"
              class="w-100 h-100 rounded-1/2"
            />
          </div>
          <div>
            <h3 style="font-size: 24px;" class="!mb-0 font-bold">{{ automation.aiAgentName }}</h3>
            <p class="!mb-0 font-normal" style="margin-bottom: 0px;">Hi! This are all your campaigns sent and in review</p>
          </div>
          <!-- <h2>{{ automation.name }}</h2> -->
        </div>

        <div class="py-4 px-4">
          <div v-if="automation.busy" class="bg-white px-4 py-5 my-3 shadow-sm">
            <b-spinner variant="primary" small class="mr-2" /> Automation is being planned...
          </div>
          <template v-if="automation.posts.length">
            <ViewPostComponent
              v-for="post in automation.posts"
              :key="post.id"
              :post="post"
              @approve="approvePost"
              @reject="rejectPost"
              @delete="promptConfirmDeletePost"
            />
          </template>
          <div v-else class="text-center p-5">
            <p class="m-0">Automation posts will show here when they are ready.</p>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="DeleteAutoPostModal" centered hide-footer hide-header>
      <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
      <div v-else class="text-center py-5">
        <h4>Delete post?</h4>
        <p>Are you sure you would like to delete this post?</p>

        <b-button variant="light" class="mr-2" @click="cancelDeletePost">Cancel</b-button>
        <b-button :disabled="isDeleting" @click="actuallyDeletePost">Delete</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import TopWizard from '~/components/TopWizard';
import { FETCH_AUTOMATION_QUERY } from '~/graphql/queries';
import ViewPostComponent from '~/components/ViewPostComponent';
import { CHANGE_POST_STATUS_MUTATION, DELETE_POST_MUTATION } from '~/graphql/mutations';

export default {
  components: {
    TopWizard,
    ViewPostComponent,
  },

  data() {
    return {
      automation: {},
      envokedForDelete: null,
      isDeleting: false,
    };
  },

  apollo: {
    automation: {
      query: FETCH_AUTOMATION_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          id: parseInt(this.$route.params.id, 10),
        };
      },
      result({ data }) {
        if (!data.automation) {
          this.automation = {};
        }
      },
      error(error) {
        error.graphQLErrors.map(error => {
          const validation = error.extensions.validation;

          if (validation && validation['id'] !== undefined) {
            this.$notify({
              group: 'main',
              type: 'native-error',
              title: 'Not found',
              text: 'Automation does not exist',
            });
            this.$router.push({ name: 'automation.list' });
          }
        });
      },
    },
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
    primaryBlog() {
      return this.automation.selectedAccounts.find(account => account.publishVariant == 'primary') || {};
    },
    secondaryBlogs() {
      return this.automation.selectedAccounts.filter(account => account.publishVariant == 'secondary');
    },
  },

  mounted() {
    const automationId = this.$route.params.id;

    window.Echo.private(`automation.${automationId}`).listen('AutomationPlanned', () => {
      if (this.$apollo.queries.automation && !this.$apollo.queries.automation.loading) {
        this.$apollo.queries.automation.refetch();
      }
    });

    window.Echo.private(`automation.${automationId}`).listen('AutomationPlanningStarted', () => {
      if (this.$apollo.queries.automation && !this.$apollo.queries.automation.loading) {
        this.updateAutomation({ busy: false });
      }
    });

    window.Echo.private(`automation.${automationId}`).listen('AutomationPlanningFailed', () => {
      if (this.$apollo.queries.automation && !this.$apollo.queries.automation.loading) {
        this.updateAutomation({ busy: false });
      }
    });
  },

  beforeDestroy() {
    window.Echo.leave(`automation.${this.$route.params.id}`);
  },

  methods: {
    goBackHandler() {
      this.$router.push({ name: 'automation.list' });
    },

    updateAutomation(updates) {
      const data = this.readQueryData();

      if (data.automation) {
        data.automation = {
          ...data.automation,
          ...updates,
        };

        this.writeQueryData(data);
      }
    },

    readQueryData() {
      const store = this.$apollo.getClient();
      // Read the data from our cache for this query.
      return store.readQuery({
        query: FETCH_AUTOMATION_QUERY,
        variables: {
          workspace: this.workspace.id,
          id: parseInt(this.$route.params.id, 10),
        },
      });
    },

    writeQueryData(data) {
      const store = this.$apollo.getClient();
      store.writeQuery({
        query: FETCH_AUTOMATION_QUERY,
        variables: {
          workspace: this.workspace.id,
          id: parseInt(this.$route.params.id, 10),
        },
        data,
      });
    },

    changeStatus(ids, status) {
      try {
        if (Array.isArray(ids)) {
          ids.map(id => {
            this.makePostBusy(id);
          });
        } else if (typeof ids === 'number') {
          this.makePostBusy(ids);
        }

        this.$apollo.mutate({
          mutation: CHANGE_POST_STATUS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            ids,
            status: status,
          },
          update: (store, { data: { changedPosts } }) => {
            // Read data from store
            const data = store.readQuery({
              query: FETCH_AUTOMATION_QUERY,
              variables: {
                workspace: this.workspace.id,
                id: parseInt(this.$route.params.id, 10),
              },
            });

            changedPosts.map(changedPost => {
              const index = data.automation.posts.findIndex(p => changedPost.id === p.id);

              if (index !== -1) {
                data.automation.posts.splice(index, 1, changedPost);
              }
            });
            // Write our data back to the cache.
            store.writeQuery({
              query: FETCH_AUTOMATION_QUERY,
              variables: {
                workspace: this.workspace.id,
                id: parseInt(this.$route.params.id, 10),
              },
              data,
            });
          },
        });
      } catch (error) {
        // Probably an error reading/writing cache
      }
    },

    makePostBusy(id) {
      const index = this.automation.posts.findIndex(p => p.id === id);
      if (index !== -1) {
        this.automation.posts.splice(index, 1, {
          ...this.automation.posts[index],
          busy: true,
        });
      }
    },

    approvePost(post) {
      this.changeStatus(post.id, 'scheduled');
    },

    rejectPost(post) {
      this.changeStatus(post.id, 'rejected');
    },

    promptConfirmDeletePost(post) {
      this.envokedForDelete = post;

      this.$refs.DeleteAutoPostModal.show();
    },

    cancelDeletePost() {
      this.envokedForDelete = null;

      this.$refs.DeleteAutoPostModal.hide();
    },

    async actuallyDeletePost() {
      this.isDeleting = true;
      await this.$apollo.mutate({
        mutation: DELETE_POST_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [this.envokedForDelete.id],
        },
        update: (store, { data: { deleted } }) => {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: FETCH_AUTOMATION_QUERY,
            variables: {
              workspace: this.workspace.id,
              id: parseInt(this.$route.params.id, 10),
            },
          });
          if (deleted) {
            const index = data.automation.posts.findIndex(a => this.envokedForDelete.id === a.id);

            if (index !== -1) {
              data.automation.posts.splice(index, 1);
            }
          }
          // Write our data back to the cache.
          store.writeQuery({
            query: FETCH_AUTOMATION_QUERY,
            variables: {
              workspace: this.workspace.id,
              id: parseInt(this.$route.params.id, 10),
            },
            data,
          });
        },
      });

      this.isDeleting = false;
      this.cancelDeletePost();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.hashtag {
  color: darken($primary, 25);
}

.post-right-img {
  width: 100px;
  height: 100px;
}
</style>
