<template>
  <!-- class="text-4xl font-bold bg-gradient-to-r from-[#e2c83b] to-[#06aa82] bg-clip-text text-transparent underline decoration-[#06aa82] decoration-2 underline-offset-2 leading-8" -->

  <div class="automation-page-wrap">
    <b-container fluid class="mb-5 mt-3 px-5">
      <!--<div class="mb-5">
        <h2 class="automation-title d-flex justify-content-between">
          Automations
          <b-button variant="success" class="rounded-pill px-4">Upgrade</b-button>
        </h2>
        <h6 class="automation-subtitle">
          Create customized campaigns by setting up a variety of filters and rules according to your needs.
        </h6>
        <hr />
      </div> -->

      <div style="background-color: transparent;" class="automation-item pt-5  d-box">
        <div class="text-right">
          <b-btn variant="primary rounded-pill py-2 px-3" v-show="!isPermittedToUseUpgrade" @click="accessUpgrade"
            >Upgrade</b-btn
          >
        </div>
        <div class=" w-100 ">
          <div class=" text-center d-flex justify-center  leading-8 position-relative  ">
            <div class="p-24 py-8" style="position: absolute;  top: 20%; padding-left: 4rem; padding-right: 4rem;">
              <h2 class="text-white">Want a Thrilling experience of AI campaign?</h2>
              <h1 class="custom-gradient-text">
                Try Our Full Ai Campaign automation tool,
              </h1>
              <h5 class="text-white">For maximum campaign efficiency. Click that button 👉</h5>
              <div>
                <button
                  @click="() => accessUpgrade('social')"
                  class="px-4 py-2 text-lg shadow"
                  style="background: linear-gradient(to right, #8dbcb0 5%, #c6ef87 50%, #e3e082 80%); border-radius: 10rem; position: absolute; bottom:-20px ; right: 0; border: none; font-weight: 500;"
                >
                  TRY IT NOW!
                </button>
              </div>
            </div>
            <img src="@/assets/images/ai/ai-frame.png" alt="" />

            <div style="position: absolute; left: 3%;" class="absolute  lg:left-32  ">
              <img style="width: 95%;" src="@/assets/images/ai/ai-image.png" alt="" />
            </div>
          </div>
          <!-- <div class="text-center">
            <h5 class="ads-card-info mb-4">New AI Automation</h5>
            <div class="">
              <h2 class="automation-title justify-content-between">
                AI Social/Blog Magic Tool
              </h2>
              <p class="ads-card-main">
                Incredible New Tool that gives new AI Power to Perpetually create content, design images, Add captions,
                posts and distribute to your channels with a from a single keyword setup. This is a complete AI
                automation magic.
              </p>
            </div>
          </div> -->
          <!-- <div class="p-3 mt-5 pb-5">
            <b-row cols="2">
              <b-col>
                <div class="ads-items pt-5 px-5">
                  <div class="top">
                    <h5>AI Social Post Automation</h5>

                    <div class="heading-desc pt-3">
                      Setup on and let AI do everything from creation to publishing using your set frequency and
                      interest
                    </div>
                  </div>

                  <div class="d-flex align-items-end justify-content-between">
                    <div class="pb-5">
                      <b-button @click="() => accessUpgrade('social')" class="font-bold whitespace-nowrap">
                        View Automation
                      </b-button>
                    </div>
                    <div class="ads-img">
                      <img src="@/assets/images/ai/automate.png" />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="ads-items pt-5 px-5">
                  <div class="top">
                    <h5>AI Blog Post Automation</h5>

                    <div class="heading-desc py-3">
                      Let AI handle 100% blog marketing, blog content creation, intro captions, and distribute to your
                      provided blogging platforms.
                    </div>
                  </div>

                  <div class="d-flex align-items-end justify-content-between">
                    <div class="pb-5">
                      <b-button @click="() => accessUpgrade('blog')" class="font-bold whitespace-nowrap">
                        View Automation
                      </b-button>
                    </div>
                    <div class="ads-img">
                      <img src="@/assets/images/ai/auto2.png" />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            -
            <div class="p-4 pb-5 row align-items-center gap-3">
              <div class="col-auto">
                <div class="bg-accent-2">
                  <svg-icon name="robot" class="bouncing-tag" size="xl" />
                </div>
              </div>
              <div class="col">
                <h3 class="name">AI Automation</h3>
                <p class="m-0 p-0 desc">
                  Supercharge your online presence with our AI-powered engine! Automate posts, ignite engagement, and
                  elevate your content effortlessly. Let innovation redefine your digital storytelling journey.
                </p>
              </div>
            </div>
          </div> -->

          <!-- <div class="text-center">
            <h5 class="ads-card-info mb-4">New AI Automation</h5>
            <div class="">
              <h2 class="automation-title justify-content-between">
                AI Social/Blog Magic Tool
              </h2>
              <p class="ads-card-main">
                Incredible New Tool that gives new AI Power to Perpetually create content, design images, Add captions,
                posts and distribute to your channels from a single keyword setup. This is a complete AI automation
                magic.
              </p>
            </div>
          </div> -->

          <!-- <div class="p-3 mt-5 pb-5">
            <b-row cols="2">
              
              <b-col>
                <div class="ads-items pt-5 px-5">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="social" value="social" v-model="selectedType" />
                    <label class="form-check-label" for="social">Select Social Automation</label>
                  </div>
                </div>
              </b-col>

              
              <b-col>
                <div class="ads-items pt-5 px-5">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="blog" value="blog" v-model="selectedType" />
                    <label class="form-check-label" for="blog">Select Blog Automation</label>
                  </div>
                </div>
              </b-col>
            </b-row>

           
            <div class="text-center mt-4">
              <b-button @click="proceed" :disabled="!selectedType" class="btn-primary">
                Proceed with {{ selectedType === 'social' ? 'Social' : 'Blog' }} Automation
              </b-button>
            </div>
          </div> -->
        </div>
      </div>

      <div class="mb-5 ">
        <h2 class="automation-title d-flex justify-content-between">
          Automation Recipes
          <!--- <b-button variant="success" class="rounded-pill px-4">Upgrade</b-button> -->
        </h2>
        <h6 class="automation-subtitle">
          Create customized campaigns by setting up a variety of filters and rules according to your needs.
        </h6>
        <hr />
      </div>
      <div class="ai-automation"></div>

      <b-row cols="3" class="automation-wrap ">
        <b-col class="">
          <div class="automation-item facebook mb-4">
            <div class="automation-image"><img src="@/assets/images/article-social.svg" alt="" /></div>
            <div class="automation-name">Article Links to Social Media</div>
            <div class="automation-desc">
              Share article links on your social media profiles, pages and groups with smart rules and filters.
            </div>
            <div class="text-center">
              <b-button
                :to="{ name: 'automation.list', params: { type: 'social', recipe: 'articles' } }"
                class="automation-btn"
                size="sm"
                variant="clear"
              >
                View Automations
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col class="">
          <div class="automation-item twitter mb-4">
            <div class="automation-image"><img src="@/assets/images/video-social.svg" alt="" /></div>
            <div class="automation-name">Videos to Social Media</div>
            <div class="automation-desc">
              Share videos on your social media profiles, pages and groups with smart rules and filters.
            </div>
            <div class="text-center">
              <b-button
                :to="{ name: 'automation.list', params: { type: 'social', recipe: 'videos' } }"
                class="automation-btn"
                size="sm"
                variant="clear"
              >
                View Automations
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col class="">
          <div class="automation-item linkedin mb-4">
            <div class="automation-image"><img src="@/assets/images/article-blog.svg" alt="" /></div>
            <div class="automation-name">Articles to Blog</div>
            <div class="automation-desc">Share articles on your blog with smart rules and filters.</div>
            <div class="text-center">
              <b-button
                :to="{ name: 'automation.list', params: { type: 'blog', recipe: 'articles' } }"
                class="automation-btn"
                size="sm"
                variant="clear"
              >
                View Automations
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col class="">
          <div class="automation-item pinterest mb-4">
            <div class="automation-image"><img src="@/assets/images/video-blog.svg" alt="" /></div>
            <div class="automation-name">Videos to Blog</div>
            <div class="automation-desc">Share videos on your blog with smart rules and filters.</div>
            <div class="text-center">
              <b-button
                :to="{ name: 'automation.list', params: { type: 'blog', recipe: 'videos' } }"
                class="automation-btn"
                size="sm"
                variant="clear"
              >
                View Automations
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col class="">
          <div class="automation-item wordpress mb-4">
            <div class="automation-image"><img src="@/assets/images/rss-feed.svg" alt="" /></div>
            <div class="automation-name">RSS Feed to Social Media</div>
            <div class="automation-desc">Share RSS feeds content on your social media.</div>
            <div class="text-center">
              <b-button
                :to="{ name: 'automation.list', params: { type: 'social', recipe: 'rss' } }"
                class="automation-btn"
                size="sm"
                variant="clear"
              >
                View Automations
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col class="">
          <div class="automation-item medium mb-4">
            <div class="automation-image">
              <img src="@/assets/images/bulk-upload.svg" style="width: 85px;" alt="" />
            </div>
            <div class="automation-name">Bulk Uploader</div>
            <div class="automation-desc">
              Schedule multiple messages to your social media channels via CSV data import.
            </div>
            <div class="text-center">
              <b-button
                :to="{ name: 'automation.list', params: { type: 'social', recipe: 'csv' } }"
                class="automation-btn"
                size="sm"
                variant="clear"
              >
                Bulk Upload Posts
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal v-model="upgradeModal" centered hide-header hide-footer>
      <div class="row relative px-3 mx-3 my-4">
        <div class="absolute close-btn">
          <b-btn @click="upgradeModal = false">
            <svg-icon name="close" size="md" />
          </b-btn>
        </div>
        <div class="col-12">
          <div class="h-100">
            <h2 class="text-center">🖐 Upgrade to the New SocialAgency AI!</h2>

            <p style="font-weight: 300; font-size: 17px;" class="my-4">
              Upgrade your account and get access to the AIO Magic Tool, for UNLIMITED AI Usage. To get exclusive access
              Click the Upgrade Button Below to Purchase and get Instant Access
            </p>

            <div class="text-center mt-5">
              <b-button variant="primary" style="font-weight: 700;" class="py-3 px-5 rounded">Upgrade Now!!</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue';
import { createNamespacedHelpers } from 'vuex';
import { can } from '~/utils/helpers';
import app from '~/main';

const accessControlNamespace = createNamespacedHelpers('accessControl');
export default {
  name: 'Automation',
  components: {
    SvgIcon,
  },
  data: () => ({
    upgradeModal: false,
  }),
  computed: {
    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
    ]),

    isPermittedToUseUpgrade() {
      const user = app.$store.state.auth?.user;
      return user && can(user, 'bypass-subscription');
    },
  },
  methods: {
    accessUpgrade(upgradeType) {
      if (!this.isPermittedToUseUpgrade) {
        this.alertToUpgrade();
      } else {
        this.$router.push({
          name: 'automation.list',
          params: { type: upgradeType || 'social', recipe: 'ai' },
        });
      }
    },
    alertToUpgrade() {
      this.upgradeModal = true;
    },
  },
};
</script>

<!-- <script>
import SvgIcon from '@/components/SvgIcon.vue';
import { createNamespacedHelpers } from 'vuex';
import { can } from '~/utils/helpers';
import app from '~/main';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  name: 'Automation',
  components: {
    SvgIcon,
  },
  data: () => ({
    selectedType: '', // Tracks the user's selection ('social' or 'blog')
    upgradeModal: false,
  }),
  computed: {
    ...accessControlNamespace.mapGetters([
      'isUserCollaboratorInWorkspace',
      'isUserApproverInWorkspace',
      'isUserAdminInWorkspace',
    ]),

    isPermittedToUseUpgrade() {
      const user = app.$store.state.auth?.user;
      return user && can(user, 'bypass-subscription');
    },
  },
  methods: {
    proceed() {
      if (!this.selectedType) return;

      if (!this.isPermittedToUseUpgrade) {
        this.alertToUpgrade();
      } else {
        this.$router.push({
          name: 'automation.list',
          params: { type: this.selectedType, recipe: 'ai' },
        });
      }
    },
    alertToUpgrade() {
      this.upgradeModal = true;
    },
  },
};
</script> -->

<style lang="scss">
@import '~@/scss/variables';

.close-btn {
  top: -20%;
  right: 0;
  padding: 10px auto;
  box-shadow: 0px 5px 5px rgba($color: #000000, $alpha: 0.03);
}
.ads-card {
  padding: 50px !important;
  background: rgba(116, 200, 116, 0.136);
  &-main {
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    font-size: 16px;
    font-weight: 300;

    &-sm {
      font-size: 20px;
      font-style: italic;
      text-decoration: underline;
    }
  }
  &-info {
    display: inline-block;
    background: $primary;
    color: white;
    border-radius: 15px;
    padding: 5px 20px;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}
.bg-grad-secondary {
  background: linear-gradient(to bottom, #feb61a16, #{$secondary} 70%);
}
.ads-upgrade-btn {
  padding: 10px 20px;
  font-weight: 700;
}
.ads-items {
  overflow: clip;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: rgb(241, 241, 241);
  background: linear-gradient(135deg, #f9f9f98c, #ffffffe3);
  justify-content: space-between;
  &:hover {
    background: $primary;
    color: white !important;
  }
  & .top {
    max-width: 300px;
  }
  & h5 {
    font-weight: 800;
  }

  & .heading-desc {
    font-weight: 300;
  }

  & .ads-img {
    width: 50%;
    height: 150px;
    scale: 0.7;
  }
}
.ads-card-ai {
  display: grid;
  background: white;
  border-radius: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: min-content;

  &:hover {
    background: $primary;
    color: white !important;
  }
}
.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}
.rounded-4 {
  border-radius: 10px;
}
.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}
.\!px-\[5px\] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.bg-accent-2 {
  background: $accent-2;
  padding: 20px;
  border-radius: 30px;
  // box-shadow: 0px 0px 5px -5px rgba(0, 0, 0, 0.1);
}
.d-box {
  color: $gray-700;
  // background: #d49f4e;
  background: white;
  width: 100%;
  border-radius: 10px;
  // box-shadow: 0px 0px -30px 50px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 40px;

  p.desc {
    font-weight: 400;
    padding-right: 40px;
  }
}
.automation-page-wrap {
  .automation-main-wrap {
    min-height: 100vh;
    padding-bottom: 50px;
  }

  .automation-title {
    font-weight: bold;
  }
  .automation-subtitle {
    width: 80%;
    font-weight: lighter;
    line-height: 1.5;
    color: $gray-700;
  }

  .automation-wrap {
    display: flex;
    flex-wrap: wrap;
    // margin-right: -45px;

    .automation-item {
      background-color: $white;
      padding: 20px 30px;
      // width: 328px;
      min-height: 300px;
      max-height: 300px;
      // margin-right: 30px;
      // margin-bottom: 30px;
      border-radius: 8px;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      bottom: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0.1s;

      .automation-name {
        font-size: 1.1rem;
        margin-bottom: 20px;
        color: $gray-700;
      }

      .automation-image {
        width: 180px;
        height: 50px;
        margin-top: 15px;
        margin-bottom: 15px;

        img {
          height: 50px;
          min-width: 85px;
          max-width: 100%;
          background: $primary;
          padding: 10px 30px;
          border-radius: 30px;
        }
      }
      .automation-desc {
        font-size: 1rem;
        margin-bottom: 20px;
        color: $gray-500;
      }

      .automation-btn {
        font-weight: bold;
        font-size: 0.8rem;
        color: $gray-600;
        border-radius: 20px;
        padding: 0.2rem 1.2rem;
      }

      &:hover {
        bottom: 5px;
        box-shadow: rgba(45, 45, 45, 0.05) 0px 4px 4px, rgba(49, 49, 49, 0.05) 0px 4px 4px,
          rgba(42, 42, 42, 0.05) 0px 8px 8px, rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
          rgba(35, 35, 35, 0.05) 0px 64px 64px;

        .automation-btn {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}
.custom-gradient-text {
  font-size: 2.25rem; /* text-4xl */
  font-weight: bold; /* font-bold */
  background: linear-gradient(to right, #e2c83b, #06aa82); /* bg-gradient-to-r from-[#e2c83b] to-[#06aa82] */
  -webkit-background-clip: text; /* bg-clip-text */
  color: transparent; /* text-transparent */
  text-underline-offset: 0.125em; /* underline-offset-2 */
  line-height: 2rem; /* leading-8 */
  border-bottom: 2px solid #06aa82;
  padding: 5px;
}
.name {
  font-weight: bold;
  font-size: 25px;
}
</style>
